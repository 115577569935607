import $ from "jquery";

(async () => {
  // quantity functions
  $(".js-decrease").each(function () {
    $(this).click(function () {
      let input = $(this).siblings(".form-field").children("input");
      let id = $(this).data("minicart-item");
      let oldValue = parseFloat(input.val());
      let newValue;
      let max_quantity = input.data("max-quantity");
      let feedbackElement = $(this).parents(".product-cart").nextAll(".feedback-quantity").first();
      let productCard = $(this).parents(".product-cart");
      oldValue <= 1 ? (newValue = oldValue) : (newValue = oldValue - 1);

      if (newValue > max_quantity) {
        feedbackElement.removeClass("d-none");
        productCard.addClass("max-quantity-on");
      } else {
        feedbackElement.addClass("d-none");
        productCard.removeClass("max-quantity-on");
      }

      input.val(newValue);
      input.trigger("change");

      // updateCart(newValue, id);
    });
  });

  $(".js-increase").each(function () {
    $(this).click(function () {
      let input = $(this).siblings(".form-field").children("input");
      let id = $(this).data("minicart-item");
      let oldValue = parseFloat(input.val());
      let newValue = oldValue + 1;
      let max_quantity = input.data("max-quantity");
      let feedbackElement = $(this).parents(".product-cart").nextAll(".feedback-quantity").first();
      let productCard = $(this).parents(".product-cart");

      if (newValue > max_quantity) {
        feedbackElement.removeClass("d-none");
        productCard.addClass("max-quantity-on");
      } else {
        feedbackElement.addClass("d-none");
        productCard.removeClass("max-quantity-on");
        input.val(newValue);
        input.trigger("change");
        // updateCart(newValue, id);
      }
    });
  });

  function updateCart(newValue, cart_id) {
    $.ajax({
      url: "index.php?route=checkout/cart/info",
      type: "post",
      data: { quantity: newValue, cart_id: cart_id },
      dataType: "json",
      success: function (json) {
        $("#cart-total").html(json.total_products);
        $(".mini-cart__total--text").html(json.total);
        $(".mini-cart__header span:first").html(
          "(" + json.total_products + ")" + " {{ text_products }}",
        );
      },
      error: function (xhr, ajaxOptions, thrownError) {
        //alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      },
    });
  }

  // function decreaseQuantity(product_id, cart_id) {
  //   $("#feedback-quantity-" + product_id + "-" + cart_id + " .text-danger").remove();
  //   let inputQ = $("#input-quantity-" + product_id + "-" + cart_id);
  //   let oldValue = parseFloat(inputQ.val());
  //   let newValue;
  //   if (oldValue <= 1) {
  //     newValue = oldValue;
  //   } else {
  //     newValue = oldValue - 1;
  //   }
  //
  //   inputQ.val(newValue);
  //   inputQ.trigger("change");
  //   // $('#light-' +product_id).html(newValue);
  //
  //   updateCart(newValue, cart_id);
  // }
  //
  // function increaseQuantity(product_id, cart_id, max_quantity) {
  //   $("#feedback-quantity-" + product_id + "-" + cart_id + " .text-danger").remove();
  //   let inputQ = $("#input-quantity-" + product_id + "-" + cart_id);
  //   let oldValue = parseFloat(inputQ.val());
  //   let newValue = oldValue + 1;
  //
  //   if (newValue > max_quantity) {
  //     $("#feedback-quantity-" + product_id + "-" + cart_id).append(
  //       '<div class="text-danger">' + "{{ reached_max }}" + "</div>",
  //     );
  //   } else {
  //     inputQ.val(newValue);
  //     inputQ.trigger("change");
  //     updateCart(newValue, cart_id);
  //   }
  //
  //   //$('#light-' +product_id).html(newValue);
  // }

  //
  // function updateCart(newValue, cart_id) {
  //   $.ajax({
  //     url: "index.php?route=checkout/cart/editFromMiniCart",
  //     type: "post",
  //     data: { quantity: newValue, cart_id: cart_id },
  //     dataType: "json",
  //     success: function (json) {
  //       $("#cart-total").html(json.total_products);
  //       $(".mini-cart__total--text").html(json.total);
  //       $(".mini-cart__header span:first").html(
  //         "(" + json.total_products + ")" + " {{ text_products }}",
  //       );
  //     },
  //     error: function (xhr, ajaxOptions, thrownError) {
  //       //alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
  //     },
  //   });
  // }

  // Utility functions
  const calculateScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth;

  const updateScrollBarWidth = () => {
    const scrollBarWidth = calculateScrollBarWidth();
    $("body").css("--sb-width", `${scrollBarWidth}px`);
  };

  const updateTransform = ($el, transformValue) => {
    if ($el.length) {
      $el.css("transform", transformValue);
    }
  };

  // get the type of the minicart and the corresponding transform value
  let typesTranslateArray;
  const miniCartClass = "cart-modal";
  const numberOfMinicartTypes = 4;
  const breakpoint = 768;

  if ($(window).width() < breakpoint) {
    typesTranslateArray = {
      "type-1": "translateX(0)",
      "type-2": "translateX(0)",
      "type-3": "translateX(0)",
    };
  } else {
    typesTranslateArray = {
      "type-1": "translateX(0)",
      "type-2": "translateX(0)",
      "type-3": "translateX(-28%)",
    };
  }

  const getMinicartType = (elementClass, numberOfTypes) => {
    for (let i = 1; i <= numberOfTypes; i++) {
      if ($("." + elementClass).hasClass(elementClass + "--type-" + i)) {
        return "type-" + i;
      }
    }
  };

  let type = getMinicartType(miniCartClass, numberOfMinicartTypes);

  const getTransformValue = (isVisible, type = "type-1") =>
    isVisible ? typesTranslateArray[type] : "translateX(100%)";

  // For preview purposes - on project finalization uncomment below delete the rest - start
  // const closeMiniCart = () => {
  //   const transformValue = getTransformValue(false);
  //   const minicart = $(".js-cart-modal");
  //
  //   if (minicart.length) {
  //     updateTransform(minicart, transformValue);
  //     minicart.removeClass("cart--open");
  //   }
  //
  //   $("body").removeClass("no-scroll");
  // };

  const closeMiniCart = ($minicart) => {
    const transformValue = getTransformValue(false, type);
    updateTransform($minicart, transformValue);
    $minicart.removeClass("cart--open");
    $("body").removeClass("no-scroll");
  };
  // Preview Purposes - end

  const toggleMinicart = ($el, show) => {
    $el.on("click", function (event) {
      event.stopPropagation();
      const transformValue = getTransformValue(show, type);
      // For preview purposes - on project finalization uncomment below delete the rest - start
      // const $minicart = $(".js-cart-modal");
      const $minicart = $(this).closest(".site-header").find(".js-cart-modal");
      // Preview Purposes - end
      if ($minicart.length) {
        updateTransform($minicart, transformValue);

        if (show) {
          $minicart.addClass("cart--open");
          $("body").addClass("no-scroll");
        } else {
          $minicart.removeClass("cart--open");
          $("body").removeClass("no-scroll");
        }
      }
    });
  };

  const handleResize = () => {
    updateScrollBarWidth();
  };

  // Initial setup
  updateScrollBarWidth();

  // For preview purposes - on project finalization uncomment below delete the rest - start
  // toggleMinicart($(".js-cart-modal-toggle"), true);
  // toggleMinicart($(".js-cart-modal-close"), false);
  $(".js-cart-modal-toggle").each(function () {
    toggleMinicart($(this), true, type);
  });
  $(".js-cart-modal-close").each(function () {
    toggleMinicart($(this), false, type);
  });
  // Preview Purposes - end

  $(window).resize(handleResize);

  // Event listeners
  $(document).on("click", (event) => {
    if (!$(event.target).closest(".js-cart-modal").length) {
      // closeMiniCart();
      $(".js-cart-modal").each(function () {
        closeMiniCart($(this));
      });
    }
  });
})();
